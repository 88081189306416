import "./bootstrap";

import i18n from "@/scripts/i18n";
import Guest from "@/views/Layouts/GuestLayout";
import { createInertiaApp, router } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createRoot } from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";

createInertiaApp({
  resolve: (name) => {
    return resolvePageComponent(
      `../views/Pages/${name}.tsx`,
      import.meta.glob("../views/Pages/**/*.tsx", { eager: false })
    ).then((module) => {
      // @ts-expect-error
      const component = module.default;
      component.layout =
        component.layout ||
        ((page) => <Guest children={page} title="Cohen Cars" />);
      return module;
    });
    // const pages = import.meta.glob("../views/Pages/**/*.tsx", {
    //   eager: true,
    // });
    // let page: any = pages[`../views/Pages/${name}.tsx`];

    // if (page && page.default) {
    //   page.default.layout =
    //     page.default?.layout ||
    //     ((page: any) => <Guest children={page} title="Test" />);
    // }
    // return page;
  },
  setup({ el, App, props }) {
    const root = createRoot(el);
    const locale = (props.initialPage.props.locale as string) || "en";
    const trackingId = import.meta.env.VITE_GA_ID;

    if (locale) {
      (window as any).axios.defaults.headers.common["X-LOCALE"] = locale;
    }
    if (trackingId) {
      ReactGA.initialize(trackingId, {
        gtagOptions: {
          send_page_view: false, // Ensure automatic tracking is disabled
        },
      });
      console.log("Tracking PRE", window.location.pathname);

      // Track initial pageview
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        location: window.location.href,
        title: document.title,
      });

      // Use 'success' event instead of 'navigate' to ensure single tracking
      router.on("success", () => {
        console.log("Tracking", window.location.pathname);
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          location: window.location.href,
          title: document.title,
        });
      });
    }
    console.log("Locale", locale);

    i18n.changeLanguage(locale);

    return root.render(
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.VITE_CAPTCHA_PUBLIC}
        scriptProps={{
          async: true, // use async for independent script execution
          appendTo: "body",
        }}
      >
        <App {...props} />
      </GoogleReCaptchaProvider>
    );
  },
});
